.full-width {
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 25px;
}


.paper-wrapper {
  position: fixed;
  display: block;
  width: 100%;
  float: left;
  overflow: hidden;
  /* margin-top: 105px; */
}

#paper_canvas {
  float: left;
  /*border: solid 1px #d3d3d3;*/
  overflow: hidden;
}

.horizontal-wrapper {
  float: right;
  /* margin-right: 5px;
  margin-top: -5px; */
}

#horizontal_scrollbar {
  float: left;
}


.vertical-wrapper {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 998;
  /* border: 1px solid black; */
}

#proband_arrow {
  height: 30px;
  width: 30px;
}

.hide-element {
  display: none;
}

.pedigree-content {
  margin-top: -30px;
  margin-left: -30px;
}